// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-courses-template-js": () => import("./../../../src/components/templates/courses-template.js" /* webpackChunkName: "component---src-components-templates-courses-template-js" */),
  "component---src-components-templates-guide-template-js": () => import("./../../../src/components/templates/guide-template.js" /* webpackChunkName: "component---src-components-templates-guide-template-js" */),
  "component---src-guides-academics-scheduling-mdx": () => import("./../../../src/guides/academics/scheduling.mdx" /* webpackChunkName: "component---src-guides-academics-scheduling-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-course-review-js": () => import("./../../../src/pages/course-review.js" /* webpackChunkName: "component---src-pages-course-review-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

